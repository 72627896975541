import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { reduxFormDomOnlyProps } from "app/utils/form/formUtils";
import "./NumberInput.scss";

class NumberInput extends React.Component {
	constructor() {
		super();
		this.decrement = this.decrement.bind(this);
		this.increment = this.increment.bind(this);
	}

	decrement() {
		const newValue = Number(this.props.field.value) - 1;

		this.props.field.onChange(newValue);

		if (typeof this.props.onValueChanged === "function") {
			this.props.onValueChanged(newValue);
		}
	}

	increment() {
		const newValue = Number(this.props.field.value) + 1;

		this.props.field.onChange(newValue);

		if (typeof this.props.onValueChanged === "function") {
			this.props.onValueChanged(newValue);
		}
	}

	render() {
		const { id, field, canIncrement, canDecrement } = this.props;

		const value = Number(field.value);

		const numberInputIncrementClass = classNames({
			"number-input__button": true,
			"number-input__button--disabled": !canIncrement(value),
		});

		const numberInputDecrementClass = classNames({
			"number-input__button": true,
			"number-input__button--disabled": !canDecrement(value),
		});

		return (
			<div className="number-input">
				<button
					type="button"
					className={numberInputDecrementClass}
					onClick={canDecrement(value) ? this.decrement : undefined}
					data-cy={`number-input-${id}-decrement`}
				>
					-
				</button>
				<input
					{...reduxFormDomOnlyProps(field)}
					disabled
					className="number-input__input"
					id={id}
					type="number"
				/>
				<button
					type="button"
					className={numberInputIncrementClass}
					onClick={canIncrement(value) ? this.increment : undefined}
					data-cy={`number-input-${id}-increment`}
				>
					+
				</button>
			</div>
		);
	}
}

NumberInput.defaultProps = {
	field: {},
	canIncrement: () => true,
	canDecrement: () => true,
};

NumberInput.propTypes = {
	field: PropTypes.object,
	id: PropTypes.string.isRequired,
	onValueChanged: PropTypes.func,
	canIncrement: PropTypes.func,
	canDecrement: PropTypes.func,
};

export default NumberInput;
