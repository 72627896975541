import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import IconDeposit from "app/pages/.shared/static/icons/IconDeposit";
import AmountContainer from "app/pages/.shared/AmountContainer.jsx";
import { DEPOSIT_PERCENTAGE, DEPOSIT_PERCENTAGE_DE } from "app/constants";
import { sendTagDepositNoteViewed } from "app/utils/analytics";

import "./PayNowNote.scss";

const PayNowNote = ({ total, shop }) => {
	const [lang] = shop.split("-");
	const isLangFr = lang === "fr";
	const isShopDe = shop === "de-DE";

	const percentage = isShopDe ? DEPOSIT_PERCENTAGE_DE : DEPOSIT_PERCENTAGE;

	const payNow = Math.ceil((total * percentage) / 100);

	useEffect(() => {
		sendTagDepositNoteViewed();
	}, []);

	const note = (
		<FormattedMessage
			id="my.bookings.pay.now.note.amount"
			tagName="span"
			values={{
				amount: (
					<AmountContainer
						amount={payNow}
						suffix={
							<FormattedMessage id="general.amount.per.person.suffix.label.short" />
						}
					/>
				),
			}}
		/>
	);

	return (
		<div className="pay-now-note">
			{isLangFr ? (
				<div className="pay-now-note__content">
					<div className="pay-now-note__icoLabel">
						<IconDeposit width={20} height={27} />
						<FormattedMessage id="my.bookings.deposit.available.label.prefix" />
					</div>
					{note}
				</div>
			) : (
				<React.Fragment>
					<IconDeposit width={20} height={27} />
					{note}
				</React.Fragment>
			)}
		</div>
	);
};

PayNowNote.propTypes = {
	total: PropTypes.number,
	shop: PropTypes.string,
};

export default React.memo(PayNowNote);
