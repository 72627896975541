import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./NumberInput.scss";
import { useField } from "formik";

const NumberInputFormik = props => {
	const { id, canIncrement, canDecrement } = props;
	const [field, meta, helpers] = useField(props);

	const decrement = useCallback(() => {
		const newValue = Number(field.value) - 1;
		helpers.setValue(newValue);
	}, [field.value]);

	const increment = useCallback(() => {
		const newValue = Number(field.value) + 1;
		helpers.setValue(newValue);
	}, [field.value]);

	const numberInputIncrementClass = classNames({
		"number-input__button": true,
		"number-input__button--disabled": !canIncrement(field.value),
	});

	const numberInputDecrementClass = classNames({
		"number-input__button": true,
		"number-input__button--disabled": !canDecrement(field.value),
	});

	return (
		<div className="number-input">
			<button
				type="button"
				className={numberInputDecrementClass}
				onClick={canDecrement(field.value) ? decrement : undefined}
				data-cy={`number-input-${id}-decrement`}
			>
				-
			</button>
			<input
				value={field.value}
				disabled
				className="number-input__input"
				id={id}
				type="number"
				data-cy={`number-input-${id}-input`}
			/>
			<button
				type="button"
				className={numberInputIncrementClass}
				onClick={canIncrement(field.value) ? increment : undefined}
				data-cy={`number-input-${id}-increment`}
			>
				+
			</button>
		</div>
	);
};

NumberInputFormik.defaultProps = {
	canIncrement: () => true,
	canDecrement: value => {
		return value !== 0;
	},
};

NumberInputFormik.propTypes = {
	id: PropTypes.string,
	canIncrement: PropTypes.func,
	canDecrement: PropTypes.func,
};

export default React.memo(NumberInputFormik);
