import React from "react";
import { getOauthIndentityProviderUrl, isOAuthenticated } from "app/utils/auth";
import { Route } from "react-router-dom";
import { getStore } from "app/configureStore";
import { STRATEGY } from "app/constants";
import { parseQueries } from "app/utils/routerUtils";

// eslint-disable-next-line react/prop-types
export default ({ children, ...rest }) => (
	<Route
		{...rest}
		render={props => {
			const state = getStore().getState();
			const strategy = state.partner.strategy;
			const { code } = parseQueries(props.location.search);

			// si j'arrive depuis une redirection du provider avec lauthorization code dans la query
			if (isOAuthenticated()) {
				const childrenWithProps = React.Children.map(children, child => {
					return React.cloneElement(child, props);
				});
				return childrenWithProps;
			} else if (!isOAuthenticated() && strategy === STRATEGY.OAUTH && !code) {
				if (typeof window !== "undefined") {
					window.location.replace(
						getOauthIndentityProviderUrl(
							window.location.origin + window.location.pathname
						)
					);
				}
			} else if (code && state.partner.strategy === STRATEGY.OAUTH) {
				const childrenWithProps = React.Children.map(children, child => {
					return React.cloneElement(child, {
						...props,
						oAuthorizationCode: code,
					});
				});
				return childrenWithProps;
			}
			return null;
		}}
	/>
);
