import PropTypes from "prop-types";
import React from "react";
import "./paragraphs.scss";

class Paragraphs extends React.PureComponent {
	render() {
		const { paragraphs = [], enableHTMLFormatting = false } = this.props;

		const paragraphNodes = paragraphs.map((paragraph, index) => {
			if (enableHTMLFormatting === true) {
				return (
					<article
						className="paragraphs__item"
						key={index}
						dangerouslySetInnerHTML={{ __html: paragraph }}
					/>
				);
			}
			return (
				<article className="paragraphs__item" key={index}>
					{paragraph}
				</article>
			);
		});

		return <section className="paragraphs">{paragraphNodes}</section>;
	}
}

Paragraphs.propTypes = {
	paragraphs: PropTypes.arrayOf(PropTypes.string),
	enableHTMLFormatting: PropTypes.bool,
};

export default Paragraphs;
