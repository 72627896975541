import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { OFFER_CONTRACT_TYPES } from "app/constants";
import { FormattedMessage } from "react-intl";
import OffersOverviewItem from "app/pages/.shared/OffersOverview/OffersOverviewItem";
import "./OffersOverview.scss";

const OffersOverview = ({ offers }) => {
	return (
		<div className="offers-overview">
			{offers.map(offer => {
				const unit =
					get(offer, "pricing.contractType") === OFFER_CONTRACT_TYPES.RENTAL ? (
						<FormattedMessage id="general.amount.per.accommodation.suffix.label.short" />
					) : (
						<FormattedMessage id="general.amount.per.person.suffix.label.short" />
					);

				const label = get(offer, "pricing.label");

				return (
					<div key={label} className="offers-overview__item">
						<OffersOverviewItem
							offerType={get(offer, "pricing.type")}
							nightCount={get(offer, "pricing.minPrice.n")}
							label={label}
							from={get(offer, "pricing.minPrice.p")}
							unit={unit}
							departureDate={get(offer, "pricing.minPrice.dd")}
							departureCity={get(offer, "pricing.minPrice.dc")}
						/>
					</div>
				);
			})}
		</div>
	);
};

OffersOverview.propTypes = {
	offers: PropTypes.array,
};

OffersOverview.defaultProps = {
	offers: [],
};

export default React.memo(OffersOverview);
