import React from "react";
import { FormattedMessage } from "react-intl";
import LogoBrand from "app/pages/.shared/LogoBrand/LogoBrand";
import { BRANDS } from "app/constants";
import "./PartnershipWithPFSLabel.scss";

const PartnershipWithPFSLabel = () => {
	return (
		<div className="partnership-with-pfs">
			<FormattedMessage id="general.partnership.with.pfs" />
			<LogoBrand brand={BRANDS.XX} shop="fr-FR" />
		</div>
	);
};

export default React.memo(PartnershipWithPFSLabel);
