import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import IconDeposit from "app/pages/.shared/static/icons/IconDeposit";

import "./PayNowAlert.scss";

const PayNowAlert = ({ shop }) => {
	const [lang] = shop.split("-");
	const isLangFr = lang === "fr";

	return (
		<div className="pay-now-alert">
			<div className="pay-now-alert__picto">
				<IconDeposit width={20} height={20} />
			</div>
			<FormattedMessage
				id={
					!isLangFr
						? "my.bookings.deposit.not.available.label"
						: "my.bookings.deposit.not.available.label.2"
				}
				values={{
					// eslint-disable-next-line react/no-multi-comp
					b: chunks => <b>{chunks}</b>,
				}}
				tagName="span"
			/>
		</div>
	);
};

PayNowAlert.propTypes = {
	shop: PropTypes.string,
};

export default React.memo(PayNowAlert);
