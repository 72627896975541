import React from "react";
import PropTypes from "prop-types";
import { FormattedDate, FormattedMessage, FormattedPlural } from "react-intl";
import PopoverInfo from "app/pages/.shared/Popover/PopoverInfo";
import { OFFER_TYPES } from "app/constants";
import AmountContainer from "app/pages/.shared/AmountContainer";
import FrequencyFlyersMarketing from "@brand/FrequencyFlyersMarketing/FrequencyFlyersMarketing";
import "./OffersOverviewItem.scss";
import TransportationPictoContainer from "app/pages/.shared/TransportationPicto/TransportationPictoContainer";

const OffersOverviewItem = ({
	offerType,
	label,
	from,
	unit,
	nightCount,
	departureCity,
	departureDate,
}) => {
	const isPriceAvailable = !isNaN(from);

	const isOfferWithFlight = offerType === OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION;

	// eslint-disable-next-line react/no-multi-comp
	const nightCountWording = (...chunks) => (
		<div className="offers-overview-item__duration">
			{chunks}{" "}
			<PopoverInfo
				popOverButtonClassName="offers-overview-item__tooltip"
				popOverContent={
					<div>
						{isOfferWithFlight ? (
							<FormattedMessage
								id="product.page.cheapest.fa.offer.info"
								values={{
									duration: nightCount,
									departureCity,
									departureDate: (
										<FormattedDate
											timeZone="UTC"
											day="2-digit"
											month="2-digit"
											year="numeric"
											value={departureDate}
										/>
									),
								}}
							/>
						) : (
							<FormattedMessage
								id="product.page.cheapest.ao.offer.info"
								values={{
									duration: nightCount,
									departureDate: (
										<FormattedDate
											timeZone="UTC"
											day="2-digit"
											month="2-digit"
											year="numeric"
											value={departureDate}
										/>
									),
								}}
							/>
						)}
					</div>
				}
			/>
		</div>
	);
	const nightCountWordingParameters = { count: nightCount };

	return (
		<div className="offers-overview-item">
			<div className="offers-overview-item__label">
				{label}
				{isOfferWithFlight && (
					<TransportationPictoContainer className="offers-overview-item__transport" />
				)}
				{isOfferWithFlight && <FrequencyFlyersMarketing showPictoOnly />}
			</div>
			<div>
				<div className="offers-overview-item__amount">
					{isPriceAvailable && (
						<AmountContainer
							amount={from}
							prefix={<FormattedMessage id="offer.per.person.from.label" />}
							suffix={unit}
						/>
					)}
				</div>
				{isPriceAvailable && (
					<FormattedPlural
						value={nightCount}
						one={
							<FormattedMessage
								id="general.nights.count.singular"
								values={nightCountWordingParameters}
							>
								{nightCountWording}
							</FormattedMessage>
						}
						other={
							<FormattedMessage
								id="general.nights.count"
								values={nightCountWordingParameters}
							>
								{nightCountWording}
							</FormattedMessage>
						}
					/>
				)}
			</div>
		</div>
	);
};

OffersOverviewItem.propTypes = {
	nightCount: PropTypes.number,
	offerType: PropTypes.string,
	from: PropTypes.number,
	label: PropTypes.string,
	unit: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	departureCity: PropTypes.string,
	departureDate: PropTypes.number,
};

export default React.memo(OffersOverviewItem);
