import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage, FormattedPlural } from "react-intl";

const PassengersCounterDisplay = ({ adults = 0, infants = 0, children = 0, prefix }) => {
	const separatorStyle = {
		marginLeft: "5px",
		marginRight: "5px",
	};

	const adultSeparatorNode =
		infants > 0 || children > 0 ? (
			<span
				style={{
					marginRight: "5px",
				}}
			>
				,
			</span>
		) : (
			false
		);

	const childrenSeparatorNode =
		infants > 0 && children > 0 ? <span style={separatorStyle}>+</span> : false;

	const prefixSeparatorNode = prefix ? <span style={separatorStyle}>/</span> : false;

	const infantsNode =
		infants > 0 ? (
			<FormattedPlural
				value={infants}
				one={
					<FormattedMessage
						values={{
							value: infants,
						}}
						id="general.infant.singular"
					/>
				}
				other={
					<FormattedMessage
						values={{
							value: infants,
						}}
						id="general.infant.plural"
					/>
				}
			/>
		) : (
			false
		);

	const childrenNode =
		children > 0 ? (
			<FormattedPlural
				value={children}
				one={
					<FormattedMessage
						values={{
							value: children,
						}}
						id="general.child.singular"
					/>
				}
				other={
					<FormattedMessage
						values={{
							value: children,
						}}
						id="general.child.plural"
					/>
				}
			/>
		) : (
			false
		);

	return (
		<div className="passengers-dounter-display">
			<FormattedPlural
				value={adults}
				one={
					<FormattedMessage
						values={{
							value: adults,
						}}
						id="general.adult.singular"
					/>
				}
				other={
					<FormattedMessage
						values={{
							value: adults,
						}}
						id="general.adult.plural"
					/>
				}
			/>
			{adultSeparatorNode}

			{childrenNode}

			{childrenSeparatorNode}

			{infantsNode}

			{prefixSeparatorNode}

			{prefix}
		</div>
	);
};

PassengersCounterDisplay.propTypes = {
	adults: PropTypes.number,
	infants: PropTypes.number,
	children: PropTypes.number,
	prefix: PropTypes.element,
};

export default React.memo(PassengersCounterDisplay);
