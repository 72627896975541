import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import NewsLetterFrequencyForm from "app/pages/.shared/NewsletterFrequency/NewsLetterFrequencyForm";
import "./FrequencyModal.scss";

const style = {
	overlay: {
		backgroundColor: "rgba(43, 56, 63, .9)",
		zIndex: 6,
	},
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		border: "none",
		background: "none",
		overflow: "hidden",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},
};

const FrequencyModal = ({ isOpen, handleSubmit, onModalClose }) => {
	const [isModalOpen, setIsOpen] = useState(isOpen);

	const closeModal = useCallback(() => {
		setIsOpen(false);
		onModalClose();
	}, []);

	useEffect(() => {
		setIsOpen(isOpen);
	}, [isOpen]);

	return (
		<Modal style={style} isOpen={isModalOpen}>
			<div className="frequency-modal">
				<div className="frequency-modal__close">
					<i className="icon icon--cross-dark" onClick={closeModal} />
				</div>
				<NewsLetterFrequencyForm onSubmit={handleSubmit} />
			</div>
		</Modal>
	);
};

FrequencyModal.propTypes = {
	isOpen: PropTypes.bool,
	handleSubmit: PropTypes.func,
	onModalClose: PropTypes.func,
};

FrequencyModal.defaultProps = {
	onModalClose: () => {},
};

export default React.memo(FrequencyModal);
